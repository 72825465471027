@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    border-width: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

*,
html {
    box-sizing: border-box;
    font-family: 'Noto Sans KR', 'Apple SD Gothic Neo', 'AppleSDGothicNeo',
        '애플 SD 산돌고딕 Neo', 'AppleSDGothicNeoWeb', -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html,
body,
#root {
    overflow-x: hidden;
    overscroll-behavior: none;
    scroll-behavior: smooth;
}
